import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  ConceptMedia,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout checkjump="privacy/facility_terms_of_use" checken="jp">
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'FACILLITY TERMS OF USE',
            sub: '施設利用規則',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/privacy/site/kv.png',
              },
              imgSp: {
                src: '/assets/images/privacy/site/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '規約・約款',
                path: '/privacy/',
              },
            ],
            current: {
              label: '施設利用規則',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap exClass="u_mbExLarge">
          <h2 className="c_headingLv2">横浜ロイヤルパークホテル施設利用規則</h2>
          <p className="u_mb25">
            当ホテルでは、お客様に安全且つ快適にご利用いただくために、次のとおり施設利用規則を定めておりますので、ご遵守いただきますようお願い申し上げます。この規則をご遵守いただけない場合は、当ホテル内諸施設のご利用をお断り申し上げますので、予めご承知おきください。
          </p>
          <h3 className="c_headingLv5">1. 適用範囲</h3>
          <p className="u_mb25">
            当ホテルの全施設（ご宿泊施設、ご宴会等施設、レストラン、バー、ランドマークスパ、クローク、ロビー、車寄せ、敷地等すべてを含みます。以下総称して「当ホテル内諸施設」といいます。）ご利用の来館者に適用させていただきます。ただし、宿泊約款、ご宴会・催事規約等に本規則と異なる規定がある場合は、当該規定が優先します。
          </p>
          <h3 className="c_headingLv5">2. 火災予防及び保安に関すること</h3>
          <p className="u_mb25">
            (1)喫煙場所以外での喫煙はお断りいたします。
            <br />
            (2)バックヤード、非常階段、機械室などお客様用以外の施設に立ち入らないでください。
          </p>
          <h3 className="c_headingLv5">
            3. お預り品、お忘れ物等の取り扱いに関すること
          </h3>
          <p className="u_mb25">
            (1)お預り品の保管は、原則お預りの日から3ヶ月間経過後は、処分させていただきます。
            <br />
            (2)お忘れ物、拾得物の処置は法令に基づいてお取り扱いさせていただきます。
          </p>
          <h3 className="c_headingLv5">
            4. 反社会的勢力等の施設利用の禁止に関すること
          </h3>
          <p>
            (1)次に掲げる組織、個人については、当ホテル内諸施設のご利用をお断りいたします。
          </p>
          <ul className="c_indentList">
            <li>(a)暴力団、暴力団員、暴力団関係団体及びその関係者</li>
            <li>
              (b)暴力団または暴力団員が事業活動を支配する法人その他の団体の関係者
            </li>
            <li>(c)反社会的団体、反社会的団体員及びその関係者</li>
            <li>
              (d)暴行、傷害、脅迫、恐喝、威圧的不当要求及びこれに類する行為が認められる場合
            </li>
            <li>
              (e)心神耗弱、薬物等による自己喪失などご自身の安全確保が困難であったり、他のお客様に危険や恐怖感、不安感を及ぼす恐れがある者
            </li>
            <li>
              (f)下記5の「その他禁止事項」について、当ホテルより注意を受けて直ちにその行為を止めなかった者
            </li>
          </ul>
          <p className="u_mb25">
            (2)前（a）ないし（c）に該当する場合は、その時点以降、一切のご利用をお断りさせていただきます。
          </p>
          <h3 className="c_headingLv5">5. その他禁止事項</h3>
          <p>
            (1)当ホテル内諸施設で、高声、放歌または喧騒な行為等、他のお客様にご迷惑となる行為。
            <br />
            (2)当ホテル内諸施設に、他のお客様のご迷惑になるものをお持ち込みになること。
          </p>
          <ul className="c_indentList">
            <li>
              (a)犬、猫、小鳥等の動物、ペット類全般（但し、盲導犬、介助犬等は除く）。
            </li>
            <li>
              (b)発火または引火しやすい火薬や揮発油類、危険性のある製品、悪臭を発するもの、著しく多量な物品、その他法令で所持を禁止されているもの等。
            </li>
          </ul>
          <p className="u_mb15">
            (3)当ホテル内諸施設で、賭博や風紀、治安を乱すような行為。
            <br />
            (4)当ホテル内諸施設で許可なく広告・宣伝物の配布や物品の販売、営業行為等をすること。
            <br />
            (5)当ホテル内諸施設で許可なくビラ等の配布、署名活動等の行為をすること。
            <br />
            (6)廊下やロビーなどに所持品を放置すること。
            <br />
            (7)当ホテル内諸施設の諸物品を他の場所へ移動したり、館外に持ち出したりすること。
            <br />
            (8)当ホテルの建築物や諸設備に傷や異物をつける等、現状に変更を加えたりすること。
            <br />
            (9)当ホテル内諸施設に、街宣車、改造車、他のお客様に不安感を及ぼしたりご迷惑となるおそれがあると当ホテルが判断する風体や車両等で、来場または駐停車すること。
            <br />
            (10)その他当ホテルが不適当と判断する行為。
          </p>
          <p className="u_mb25">
            附則　本規則は、平成19年3月1日より施行します。
          </p>
          <p className="u_tar">
            (制定)平成19年3月1日
            <br />
            (改定)平成20年8月1日
            <br />
            (改定)2023年5月9日
          </p>
        </LWrap>
      </section>
      {/* <ConceptMedia /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
